import HttpHelper from '../http-helper';
import { createApp } from 'vue';
import Loading from './Loading.vue';

let login = document.querySelector('.Admin__login');

if (login) {
	createApp({
		template: `
			<div class="Admin__login-container" :class="{'Admin__login-container--loading': loading}">
				<p v-html="error" class="Admin__login-error" v-if="error"></p>
			
				<form v-if="step === 'login'" @submit.prevent="login($event)" action="/admin/login" method="POST">
					<input type="text" name="username" v-model="username" autocomplete="username" :placeholder="trans('login.username')">
					<input type="password" name="password" v-model="password" autocomplete="current-password" :placeholder="trans('login.password')">

					<button type="submit" :disabled="loading">{{ trans('login.submit') }}</button>
					<button type="button" @click="step = 'forgot-password'">{{ trans('login.forgot-password') }}</button>
				</form>

				<form v-if="step === 'forgot-password'" @submit.prevent="forgot($event)" action="/admin/forgot" method="POST">
					<p v-if="!error" v-html="trans('login.request-code-text')"></p>
				
					<input type="text" name="username" v-model="username" autocomplete="username" :placeholder="trans('login.username')">
					<input type="text" name="phone" v-model="phone" :placeholder="trans('login.phone')">

					<button type="submit" :disabled="loading">{{ trans('login.request-code') }}</button>
					<button type="button" @click="step = 'login'">{{ trans('login.back') }}</button>
				</form>

				<form v-if="step === 'enter-code'" @submit.prevent="step = 'enter-password'">
					<p v-if="!error" v-html="trans('login.enter-code-text')"></p>
				
					<input ref="code" type="text" name="code" v-model="code" maxlength="6" inputmode="numeric" pattern="[0-9]*" autocomplete="one-time-code" :placeholder="trans('login.code')">

					<button type="submit" :disabled="loading || code.length !== 6">{{ trans('login.continue') }}</button>
					<button type="button" @click="step = 'login'">{{ trans('login.back') }}</button>
				</form>

				<form v-if="step === 'enter-two-factor'" @submit.prevent="twoFactor($event)" action="/admin/two-factor" method="POST">
					<p v-if="!error" v-html="trans('login.enter-code-text')"></p>
				
					<input ref="code" type="text" name="code" v-model="code" maxlength="6" inputmode="numeric" pattern="[0-9]*" autocomplete="one-time-code" :placeholder="trans('login.code')">

					<button type="submit" :disabled="loading || code.length !== 6">{{ trans('login.continue') }}</button>
					<button type="button" @click="step = 'login'">{{ trans('login.back') }}</button>
				</form>

				<form v-if="step === 'enter-password'" @submit.prevent="resetPassword($event)" action="/admin/reset" method="POST">
					<p v-if="!error" v-html="trans('login.reset-password-text')"></p>

					<div style="height: 0; overflow: hidden;">
						<input type="text" name="username" v-model="username" readonly autocomplete="username" :placeholder="trans('login.username')">
					</div>

					<input type="password" name="password" v-model="new_password" autocomplete="new-password" :placeholder="trans('login.new-password')">

					<button type="submit" :disabled="loading || code.length !== 6">{{ trans('login.confirm') }}</button>
					<button type="button" @click="step = 'login'">{{ trans('login.back') }}</button>
				</form>

				<div v-if="step === 'new-user'">
					<p v-html="trans('login.new-user')" style="margin-bottom: 24px"></p>
					<button type="submit" @click="step = 'forgot-password'">{{ trans('login.continue') }}</button>
				</div>

				<div v-if="step === 'too-many-attempts'">
					<button type="submit" @click="step = 'login'">{{ trans('login.back') }}</button>
				</div>

				<loading></loading>
			</div>
		`,

		data() {
			return {
				loading: false,
				hidden: false,
				error: '',
				username: '',
				password: '',
				new_password: '',
				phone: '',
				code: '',
				trans: window.trans,
				step: 'login'
			}
		},

		watch: {
			step(step, last_step) {
				if (last_step && !['enter-password'].includes(last_step) && !['too-many-attempts'].includes(step)) {
					this.error = '';
				}

				if (step === 'enter-code') {
					this.code = '';
				}

				this.new_password = '';
			}
		},

		methods: {
			login(e) {
				this.loading = true;
				this.error = '';

				HttpHelper.defaultHeaders().post(e.target.action, {
					username: this.username,
					password: this.password,
					page: window.location.pathname.replace(/^\/|\/$/g, '').replace(/\/login$/g, '')
				}).then(({ data }) => {
					if (data.status === 'success') {
						window.location.href = data.redirect;
					} else if (data.status === 'new-user') {
						this.step = 'new-user';
						this.loading = false;
					} else if (data.status === 'two-factor') {
						this.step = 'enter-two-factor';

						setTimeout(() => {
							this.loading = false;
							this.$refs.code.focus();
						}, 500);
					}
				}).catch((e) => {
					try {
						let status = e.response.data.status;
						let message = e.response.data.message;

						if (status === 'too-many-attempts') {
							this.error = message;
							this.step = 'too-many-attempts';
							this.loading = false;
						} else {
							this.error = message;
							this.loading = false;
						}
					} catch (e) {
						window.location.reload();
					}
				});
			},

			twoFactor(e) {
				this.loading = true;
				
				HttpHelper.defaultHeaders().post(e.target.action, {
					username: this.username,
					code: this.code
				}).then(({ data }) => {
					if (data.status === 'success') {
						window.location.href = data.redirect;
					} else {
						this.error = data.message;
						this.loading = false;
					}
				}).catch((e) => {
					try {
						let status = e.response.data.status;
						let message = e.response.data.message;

						if (status === 'error-two-factor') {
							this.error = message;
						} else if (status === 'too-many-attempts') {
							this.error = message;
							this.step = 'too-many-attempts';
						} 

						this.loading = false;
					} catch (e) {
						window.location.reload();
					}
				});
			},

			forgot(e) {
				this.loading = true;

				HttpHelper.defaultHeaders().post(e.target.action, {
					username: this.username,
					phone: this.phone
				}).then(({ data }) => {
					if (data.status === 'success') {
						this.step = 'enter-code';
						
						setTimeout(() => {
							this.loading = false;
							this.$refs.code.focus();
						}, 500);
					}
				}).catch((e) => {
					try {
						this.error = e.response.data.message;
						this.loading = false;
					} catch (e) {
						window.location.reload();
					}
				});
			},

			resetPassword(e) {
				this.loading = true;

				HttpHelper.defaultHeaders().post(e.target.action, {
					username: this.username,
					password: this.new_password,
					code: this.code
				}).then(({ data }) => {
					if (data.status === 'success') {
						if (data.status === 'success') {
							window.location.reload();
						}
					}
				}).catch((e) => {
					try {
						let status = e.response.data.status;
						let message = e.response.data.message;

						if (status === 'code-error') {
							this.step = 'enter-code';
							this.error = message;
						} else if (status === 'too-many-attempts') {
							this.step = 'too-many-attempts';
							this.error = message;
						}  else if (status === 'password-strength-error') {
							this.error = message;
						}

						this.loading = false;
					} catch (e) {
						window.location.reload();
					}
				});
			}
		},

		components: {
			Loading
		}
	}).mount('.Admin__login');
}


// jQuery(function($) {
// 	var reset_password = false;

// 	$('body').on('submit', '.Admin__login form', function(e) {
// 		var form = $(this);
// 		var login_error = form.find('.Admin__login-error');
// 		var forgot_error = form.find('.Admin__login-form-forgot-error');

// 		form.find('button').attr('disabled', true);

// 		$.post({
// 			url: form.attr('action'),
// 			data: form.serialize(),
// 			success: function(data) {
// 				login_error.hide();

// 				if(data.status == "success") {
// 					if(reset_password) {
// 						$('.Admin__login').removeClass('Admin__login--forgot').addClass('Admin__login--reset');
// 						$('.Admin__login-form-reset input[name="username"]').val(form.find('input[name="username"]').val());
// 						reset_password = false;
// 					} else {
// 						window.location = data.redirect;
// 					}
// 				} else if(data.status == "two-factor") {
// 					$('.Admin__login').addClass('Admin__login--two-factor');
// 					$('.Admin__login-form-two-factor input[name="username"]').val(data.username);
// 					setTimeout(function() {
// 						$('.Admin__login-form-two-factor input[name="code"]').focus();
// 					});
// 				} else if(data.status == "banned") {
// 					$('.Admin__login-form-two-factor-error').html(data.message);
// 					$('.Admin__login').addClass('Admin__login--two-factor-banned');
// 				} else if(data.status == "error-two-factor") {
// 					$('.Admin__login-form-two-factor-error').html(data.message);
// 					$('.Admin__login').addClass('Admin__login--two-factor-error');
// 				} else if(data.status == "forgot-error") {
// 					forgot_error.show().html(data.message);
// 					$('.Admin__login-form-forgot-text').hide();
// 				} else if(data.status == "code-error" || data.status == "password-repeat-error") {
// 					$('.Admin__login-form-reset-error').html(data.message);
// 				} else if (data.status == "error") {
// 					login_error.show().text(data.message);
// 				} else {
// 					if (window.location.href.indexOf('/login')) {
// 						window.location.reload();
// 					} else {
// 						window.location = window.location.href + '/login';
// 					}
// 				}

// 				if (data.status != "success") {
// 					form.find('button').removeAttr('disabled');
// 				}
// 			},
// 			error: function(e) {
// 				form.find('button').removeAttr('disabled');
				
// 				if (e.status === 419) {
// 					setTimeout(function () {
// 						window.location.reload();
// 					}, 100);
// 				} else if (e.status === 429) {
// 					if (e.responseJSON.status === 'forgot-error') {
// 						forgot_error.show().html(e.responseJSON.message);
// 						$('.Admin__login-form-forgot-text').hide();
// 					} else {
// 						login_error.show().text(e.responseJSON.message);
// 					}
// 				} else {
// 					alert('Error!');
// 				}
// 			}
// 		});

// 		e.preventDefault();
// 	});

// 	$('body').on('click', '.Admin__login-back', function() {
// 		$('.Admin__login').removeClass('Admin__login--reset Admin__login--forgot');
// 		$('.Admin__login-form-forgot-error').hide();
// 		$('.Admin__login-form-forgot-text').show();
// 	});

// 	$('body').on('click', '.Admin__login-forgot', function() {
// 		$('.Admin__login').removeClass('Admin__login--reset').addClass('Admin__login--forgot');
// 		reset_password = true;
// 	});
// });