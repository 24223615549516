import axios from 'axios';

// axios.defaults.headers['X-CSRF-TOKEN'] = document.querySelector('#_token').getAttribute('value') || null;
// axios.defaults.headers['X-ALPA-LANGUAGE'] = document.querySelector('html').getAttribute('lang') || null;

export default class HttpHelper {

    static defaultHeaders() {
        axios.defaults.headers['X-CSRF-TOKEN'] = document.querySelector('#_token').getAttribute('value') || null;
        axios.defaults.headers['X-ALPA-LANGUAGE'] = document.querySelector('html').getAttribute('lang') || null;

        return axios;
    }

    static get(url) {
        return axios.get(url);
    }

    static post(url, data) {
        return axios.post(url, data);
    }

}